import { yupResolver } from "@hookform/resolvers/yup";
import { getNames, registerLocale } from "i18n-iso-countries";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import TriangleDown from "../assets/icons/triangle-down.svg";
import { Layout, SEOTags } from "../components";
import "../styles/form-toast.scss";
import * as styles from "../styles/form.module.scss";
import { submitFormData } from "../utilities/formUtilities";

registerLocale(require("i18n-iso-countries/langs/en.json"));

const JoinForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState([]);

  const countryOptions = Object.entries(
    getNames("en", { select: "alias" })
  ).map(([value, label]) => ({ label, value }));

  // From https://github.com/dockwa/simple-react-validator/blob/master/src/simple-react-validator.js#L42
  const phoneRegex =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const formSchema = yup
    .object({
      form: yup.string(),
      name: yup.string().required("This field is required"),
      email: yup.string().required("This field is required"),
      contact: yup
        .string()
        .required("This field is required")
        .matches(phoneRegex, "Please enter a valid phone number"),
      country: yup
        .object({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required("This field is required")
        .default(null)
        .nullable(),
      role: yup
        .string()
        .required("This field is required")
        .default(null)
        .nullable(),
      cv: yup
        .string()
        .url("Please enter a valid url")
        .required("This field is required"),
      transcript: yup.string().url("Please enter a valid url"),
      other: yup.string().url("Please enter a valid url"),
    })
    .required();

  // Add url to description for "About this role" link
  const roles = [
    { title: "Engineer", description: "" },
    { title: "DevOps", description: "" },
    { title: "Designer", description: "" },
    { title: "Product Manager", description: "" },
    { title: "Accountant", description: "" },
  ];

  const renderRadioButtons = () =>
    roles.map((role) => (
      <Col
        xs={6}
        xxl={4}
        className={styles.radioOption}
        key={`radio-${role.title}`}
      >
        <div className={styles.radioText}>
          <label htmlFor={role.title}>
            <input
              type="radio"
              id={role.title}
              value={role.title}
              {...register("role")}
            />
            {role.title}
          </label>
          {role.description && (
            <a
              className={styles.about}
              target="_blank"
              href={role.description}
              rel="noreferrer"
            >
              About the role
            </a>
          )}
        </div>
      </Col>
    ));

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <TriangleDown />
      </components.DropdownIndicator>
    );
  };

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "Country__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "nearest",
        });
      }
    }, 15);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      form: "Join our team",
      country: null,
      role: null,
    },
  });

  useEffect(() => {
    const cache = JSON.parse(sessionStorage.getItem("careersForm"));
    if (cache) setInitialValues(cache);
  }, []);

  useEffect(() => {
    reset({
      form: "Join our team",
      name: initialValues.name,
      email: initialValues.email,
      contact: initialValues.contact,
      country: initialValues.country,
      role: initialValues.role,
      cv: initialValues.cv,
      transcript: initialValues.transcript,
      other: initialValues.other,
    });
  }, [reset, initialValues]);

  const watchFields = watch();

  useEffect(() => {
    const filled = !Object.values(watchFields).every(
      (item, index) =>
        (index === 0 && item === "Join our team") ||
        typeof item === "undefined" ||
        item === "" ||
        item === null
    );

    if (filled) {
      sessionStorage.setItem("careersForm", JSON.stringify(watchFields));
    } else sessionStorage.removeItem("careersForm");
  }, [watchFields]);

  const onSubmit = handleSubmit(async (data) => {
    let cleanData = data;
    cleanData.country = data.country.label;

    const onSuccess = () => {
      setIsSubmitting(true);
      toast("Successfully submitted form", { type: "success" });
      setTimeout(() => {
        sessionStorage.removeItem("careersForm");
        window.location.reload();
      }, 1500);
    };

    const onFormcarryError = () => {
      toast("Error submitting the form\nPlease try again", {
        type: "error",
      });
    };

    const onNetworkError = () => {
      toast("Error submitting the form\nPlease refresh the page", {
        type: "error",
      });
    };

    try {
      submitFormData(
        "https://formcarry.com/s/JJNkxKUzJ",
        cleanData,
        onSuccess,
        onFormcarryError,
        onNetworkError
      );
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <Layout layoutClassName="contact-page" hideContactLink>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        pauseOnHover={false}
      />
      <div className={styles.formpage}>
        <h1>Join our team</h1>
        <form className={styles.form} onSubmit={onSubmit}>
          <Row className={`${styles.row} justify-content-between`}>
            <Col md={12} lg={6} className={styles.text}>
              <h3 className={styles.title}>Let's work together</h3>
              <p className={styles.subtitle}>
                We are currently looking for developers and designers who are
                hard working and crazy talented.
                <br />
                <br />
                Up for the challenge?
                <br />
                <br />
                Shoot us an email with a link to your portfolio and we’ll get
                back to you in around 3-5 working days
              </p>
            </Col>
            <Col md={12} lg={6} className={styles.fields}>
              <div className={styles.field}>
                <label htmlFor="name">FULL NAME*</label>
                <input
                  id="name"
                  placeholder="Enter full name"
                  {...register("name")}
                />
                {errors.name && (
                  <p className={styles.error}>{errors.name.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="email">EMAIL*</label>
                <input
                  id="email"
                  placeholder="Enter email"
                  {...register("email")}
                />
                {errors.email && (
                  <p className={styles.error}>{errors.email.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="contact">CONTACT NUMBER*</label>
                <input
                  id="contact"
                  placeholder="Enter contact no."
                  {...register("contact")}
                />
                {errors.contact && (
                  <p className={styles.error}>{errors.contact.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="country">COUNTRY*</label>
                <Controller
                  control={control}
                  name="country"
                  render={({ field }) => (
                    <Select
                      classNames={{
                        control: () => styles.control,
                        menu: () => styles.menu,
                        input: () => styles.input,
                        indicatorSeparator: () => styles.indicatorSeparator,
                        singleValue: () => styles.singleValue,
                        placeholder: () => styles.placeholder,
                        option: (state) => {
                          if (state.isFocused) return styles.focus;
                          else if (state.isSelected) return styles.select;
                        },
                        valueContainer: () => styles.valueContainer,
                      }}
                      components={{ DropdownIndicator }}
                      options={countryOptions}
                      placeholder="Enter country"
                      classNamePrefix={"Country"}
                      onMenuOpen={onMenuOpen}
                      {...field}
                    />
                  )}
                />
                {errors.country && (
                  <p className={styles.error}>{errors.country.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="role">SELECT ROLE</label>
                <Row className={`gx-1 ${styles.radioOptions}`}>
                  {renderRadioButtons()}
                </Row>
                {errors.role && (
                  <p className={styles.error}>{errors.role.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="cv">INSERT CV/PORTFOLIO LINK*</label>
                <input
                  id="cv"
                  placeholder="Insert Link to website/portfolio/CV"
                  {...register("cv")}
                />
                {errors.cv && (
                  <p className={styles.error}>{errors.cv.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="transcript">SCHOOL TRANSCRIPT URL</label>
                <input
                  id="transcript"
                  placeholder="Insert Link to school transcript"
                  {...register("transcript")}
                />
                {errors.transcript && (
                  <p className={styles.error}>{errors.transcript.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="other">OTHER REVELANT URL</label>
                <input
                  id="other"
                  placeholder="Insert Link to other important documents and files"
                  {...register("other")}
                />
                {errors.other && (
                  <p className={styles.error}>{errors.other.message}</p>
                )}
              </div>
              <button type="submit" disabled={isSubmitting}>
                Send form
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </Layout>
  );
};

export default JoinForm;

export const Head = ({ location }) => (
  <SEOTags title={"Careers | Mahusai Global"} pathname={location.pathname} />
);
